const colors = {
  main: {
    dark: '#535d63',
    light: '#8d9397'
  }
};

const borderWidth = '2px';

const styles = {
  borderWidth,
  colors
};

export default styles;
